import { Container } from '@/components/container'
// import { ResponsiveFilter } from '@/components/filter'
import { Icon } from '@/components/icons'
import Logo from '@/components/logo'
import { useTheme } from '@/components/theme-provider'
import { Avatar } from '@/components/ui/avatar'
import { Button, buttonStyles } from '@/components/ui/button'
import { cn } from '@/components/ui/primitive'
import { VerifyBadge } from '@/components/verify-badge'
import { __ } from '@/lib/utils'
import { PageData } from '@/types/generated'
import { usePage } from '@inertiajs/react'
import { useState } from 'react'
import { Link, Modal, Separator } from 'ui'
import { CommandPalette } from './command-palette'

export function ResponsiveNavbar(props: any) {
    const { auth, cartCountGlobal } = usePage<PageData>().props
    const { theme, setTheme } = useTheme()
    const [isThemeSwitcher, setIsThemeSwitcher] = useState(false)
    const [isNavbar, setIsNavbar] = useState(false)
    const [open, setOpen] = useState(false)

    return (
        <nav className="navbar-transition block w-full max-w-full bg-bg/70 px-0 py-2.5 backdrop-blur-lg backdrop-filter md:hidden">
            <CommandPalette setOpen={setOpen} open={open} />
            <Container>
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-2">
                        <Link href={'/'}>
                            <Logo className="size-6" />
                        </Link>
                    </div>
                    <div className="flex items-center gap-x-4">
                        <div className="flex items-center gap-x-1.5">
                            <Button
                                shape="circle"
                                appearance="plain"
                                size="small"
                                onPress={() => setOpen(true)}
                                aria-label="search"
                            >
                                <Icon iconName="Search" size="xl" appearance="label" />
                            </Button>
                            {auth.user && (
                                <>
                                    <Link
                                        href="/chats"
                                        aria-label="chat"
                                        className={buttonStyles({
                                            shape: 'circle',
                                            appearance: 'plain',
                                            size: 'square-petite',
                                        })}
                                    >
                                        <Icon iconName="Chat" size="xl" appearance="icon" />
                                    </Link>
                                    <Link
                                        href="/carts"
                                        aria-label="chat"
                                        className={cn(
                                            buttonStyles({
                                                shape: 'circle',
                                                appearance: 'plain',
                                                size: 'small',
                                            }),
                                        )}
                                    >
                                        <Icon iconName="Bag" size="xl" appearance="label" />
                                        <div className="font-mono text-xs">{cartCountGlobal}</div>
                                    </Link>
                                </>
                            )}
                        </div>
                        {auth.user ? (
                            <Button
                                aria-label="auth"
                                className="text-muted-foreground hover:text-foreground relative transition duration-200 focus:outline-none"
                                appearance="plain"
                                size="square-petite"
                                shape="circle"
                                onPress={() => setIsNavbar(true)}
                            >
                                <VerifyBadge className="z-10" check={auth.user.is_verify} />
                                <Avatar
                                    size="medium"
                                    src={auth.user.gravatar}
                                    initials={auth.user.initials?.substring(0, 1)}
                                />
                            </Button>
                        ) : (
                            <Button
                                className="text-muted-foreground hover:text-foreground transition duration-200 focus:outline-none"
                                appearance="plain"
                                size="square-petite"
                                shape="circle"
                                aria-label="menu"
                                onPress={(e) => setIsNavbar(true)}
                            >
                                <Icon iconName="List" size="xl" appearance="label" />
                            </Button>
                        )}
                        <Modal>
                            <Modal.Content
                                isBlurred
                                classNames={{ content: 'bg-bg' }}
                                closeButton={false}
                                isOpen={isNavbar}
                                onOpenChange={setIsNavbar}
                            >
                                {auth.user && (
                                    <Modal.Header>
                                        <Modal.Title>{auth.user.name}</Modal.Title>
                                        <Modal.Description>{auth.user.email}</Modal.Description>
                                    </Modal.Header>
                                )}
                                <Modal.Body>
                                    <Separator />
                                    <Link
                                        className={cn(
                                            buttonStyles({ appearance: 'plain' }),
                                            '-mx-4 flex justify-start font-normal',
                                        )}
                                        onPress={(e) => {
                                            setIsNavbar(false)
                                        }}
                                        href="/"
                                    >
                                        <Icon iconName="House" size="xl" appearance="label" />
                                        {__('Beranda')}
                                    </Link>
                                    <Link
                                        className={cn(
                                            buttonStyles({ appearance: 'plain' }),
                                            '-mx-4 flex justify-start font-normal',
                                        )}
                                        onPress={(e) => {
                                            setIsNavbar(false)
                                        }}
                                        href="/articles"
                                    >
                                        <Icon iconName="JournalBookmark" size="xl" appearance="label" />
                                        {__('Artikel')}
                                    </Link>
                                    <Link
                                        className={cn(
                                            buttonStyles({ appearance: 'plain' }),
                                            '-mx-4 flex justify-start font-normal',
                                        )}
                                        onPress={(e) => {
                                            setIsNavbar(false)
                                        }}
                                        href="/series"
                                    >
                                        <Icon iconName="Collection" size="xl" appearance="label" />
                                        {__('Seri')}
                                    </Link>
                                    <Link
                                        className={cn(
                                            buttonStyles({ appearance: 'plain' }),
                                            '-mx-4 flex justify-start font-normal',
                                        )}
                                        onPress={(e) => {
                                            setIsNavbar(false)
                                        }}
                                        href="/products"
                                    >
                                        <Icon iconName="Box" size="xl" appearance="label" />
                                        {__('Etalase')}
                                    </Link>
                                    <Separator />
                                    {auth.user ? (
                                        <>
                                            <Link
                                                className={cn(
                                                    buttonStyles({ appearance: 'plain' }),
                                                    '-mx-4 flex justify-start font-normal',
                                                )}
                                                href="/dashboard"
                                            >
                                                <Icon iconName="Speedometer" size="xl" appearance="label" />
                                                {__('Dashboard')}
                                            </Link>
                                            <Link
                                                className={cn(
                                                    buttonStyles({ appearance: 'plain' }),
                                                    '-mx-4 flex justify-start font-normal',
                                                )}
                                                href={route('profile.edit')}
                                            >
                                                <Icon iconName="Gear" size="xl" appearance="label" />
                                                {__('Pengaturan')}
                                            </Link>
                                            <Separator />
                                            <Button
                                                className="-mx-4 flex justify-start font-normal"
                                                appearance="plain"
                                                onPress={() => setIsThemeSwitcher(true)}
                                            >
                                                <Icon iconName="Toggles2" size="xl" appearance="label" />
                                                {__('Tema')}
                                            </Button>
                                            <Separator />
                                            <Link
                                                className={cn(
                                                    buttonStyles({ appearance: 'plain' }),
                                                    '-mx-4 flex justify-start font-normal',
                                                )}
                                                href="/logout"
                                            >
                                                <Icon iconName="BoxArrowRight" size="xl" appearance="label" />
                                                {__('Keluar')}
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                className="-mx-4 flex justify-start font-normal"
                                                appearance="plain"
                                                onPress={() => setIsThemeSwitcher(true)}
                                            >
                                                <Icon iconName="Toggles2" size="xl" appearance="label" />
                                                {__('Tema')}
                                            </Button>
                                            <Separator />
                                            <Link
                                                className={cn(
                                                    buttonStyles({ appearance: 'plain' }),
                                                    '-mx-4 flex justify-start font-normal',
                                                )}
                                                href="/login"
                                            >
                                                <Icon iconName="BoxArrowLeft" size="xl" appearance="label" />
                                                {__('Masuk')}
                                            </Link>
                                        </>
                                    )}
                                </Modal.Body>
                            </Modal.Content>

                            <Modal.Content
                                isBlurred
                                classNames={{ content: 'bg-bg' }}
                                closeButton={false}
                                isOpen={isThemeSwitcher}
                                onOpenChange={setIsThemeSwitcher}
                            >
                                <Button
                                    className="-mx-4 flex justify-start font-normal"
                                    appearance="plain"
                                    aria-label={'Switch to light mode'}
                                    onPress={() => {
                                        setTheme('light')
                                        setIsThemeSwitcher(false)
                                    }}
                                >
                                    <Icon iconName="Sun" size="xl" appearance="label" />
                                    {__('Terang')}
                                </Button>
                                <Button
                                    className="-mx-4 flex justify-start font-normal"
                                    appearance="plain"
                                    aria-label={'Switch to dark mode'}
                                    onPress={() => {
                                        setTheme('dark')
                                        setIsThemeSwitcher(false)
                                    }}
                                >
                                    <Icon iconName="Moon" size="xl" appearance="label" />
                                    {__('Gelap')}
                                </Button>
                                <Button
                                    className="-mx-4 flex justify-start font-normal"
                                    appearance="plain"
                                    aria-label={'Switch to ' + theme === 'light' ? 'dark' : 'light' + 'mode'}
                                    onPress={() => {
                                        setTheme('system')
                                        setIsThemeSwitcher(false)
                                    }}
                                >
                                    <Icon iconName="Laptop" size="xl" appearance="label" />
                                    {__('Sistem')}
                                </Button>
                            </Modal.Content>
                        </Modal>
                    </div>
                </div>
            </Container>
        </nav>
    )
}
