import './bootstrap'
import '../css/app.css'

import { createRoot, hydrateRoot } from 'react-dom/client'
import { createInertiaApp } from '@inertiajs/react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { Ziggy } from '@/ziggy'
import { useRoute } from 'ziggy-js'
import { Provider } from '@/providers'
import AppLayout from '@/layouts/app-layout'
import { StrictMode } from 'react'
//@ts-ignore
import { registerSW } from 'virtual:pwa-register'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel'

const updateSW = registerSW({
    onNeedRefresh() {
        if (confirm('New content available. Reload?')) {
            updateSW(true)
        }
    },
})

createInertiaApp({
    title: (title) => `${title} / ${appName}`,
    resolve: (name) => {
        const page = resolvePageComponent(`./pages/${name}.tsx`, import.meta.glob('./pages/**/*.tsx'))
        page.then((module: any) => {
            if (!module.default.layout) {
                module.default.layout = (page: React.ReactNode) => <AppLayout children={page} />
            }
        })

        return page
    },

    setup({ el, App, props }) {
        window.route = useRoute(Ziggy as any)
        const appElement = (
            <StrictMode>
                <Provider>
                    <App {...props} />
                </Provider>
            </StrictMode>
        )
        if (import.meta.env.DEV) {
            createRoot(el).render(appElement)
            return
        }

        hydrateRoot(el, appElement)
    },
    progress: {
        color: '#71717A',
    },
})
